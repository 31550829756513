.app-footer.app-footer-auth {
    background: none;
    border: none;
}
.app-footer.app-footer-auth .footer-wrap {
    max-width: 100% !important;
}
.app-footer.app-footer-auth #page-breadcrumbs {
    display: none !important;
}
.app-footer.app-footer-auth #app-version {
    width: 50%;
}
.app-footer.app-footer-auth #app-version > div.justify-content-md-end {
    justify-content: center !important;
    color: #fff;
}
