@import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i");

:root {
    --main-color: #1f2022;
    --main-bg: #fff;
    --boxed-width: 1140px;
    --navbar-color: #000;
    --navbar-bg: #fff;
    --navbar-2-color: #000;
    --navbar-2-bg: #fbfbfb;
    --navbar-dark-color: #fff;
    --navbar-dark-bg: #1f2022
}

@-webkit-keyframes bounce {
    to {
        -webkit-transform: translateY(60px);
        transform: translateY(60px)
    }
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes bounce {
    to {
        -webkit-transform: translateY(60px);
        transform: translateY(60px)
    }
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.app-loader {
    -webkit-box-align: center;
    align-items: center;
    background: #fff;
    color: #336cfb;
    display: -webkit-box;
    display: flex;
    font-size: 30px;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99999
}

.app-loader.main-loader {
    z-index: 999999
}

.app-loader.main-loader .loader-box {
    -webkit-box-align: end;
    align-items: flex-end;
    display: -webkit-box !important;
    display: flex !important
}

.app-loader.main-loader .loader-box .bounceball {
    display: inline-block;
    height: 95px;
    position: relative;
    -webkit-animation: bounce .5s ease-in infinite alternate;
    animation: bounce .5s ease-in infinite alternate;
    width: 30px
}

.app-loader.main-loader .loader-box .bounceball:before {
    background: #336cfb;
    border-radius: 50%;
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    top: 0;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    width: 30px
}

.app-loader.main-loader .loader-box .text {
    display: -webkit-box;
    display: flex;
    color: #1f2022;
    margin-left: 15px
}

.app-loader.main-loader .loader-box .text span {
    color: #336cfb;
    font-size: 15px
}

.loaded .app-loader {
    opacity: 0;
    visibility: hidden
}

html {
    font-size: 14px
}

body, html {
    line-height: 1.43
}

body {
    background: #fff;
    background: var(--main-bg);
    color: #1f2022;
    color: var(--main-color);
    font-family: Mulish, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

button, input, optgroup, select, textarea {
    font: inherit
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: Mulish, sans-serif;
    font-weight: 700;
    line-height: 1.43;
    letter-spacing: .01em;
    margin-top: 1.42857rem;
    margin-bottom: 1.42857rem
}

.h1, h1 {
    font-size: 3.2rem;
    line-height: 1.12;
    margin-bottom: 2.14286rem
}

.h2, h2 {
    font-size: 2.85714286rem;
    line-height: 1
}

.h3, h3 {
    font-size: 2rem;
    line-height: 1.42857143
}

.h4, h4 {
    font-size: 1.71428572rem;
    line-height: 1.25
}

.h5, h5 {
    font-size: 1.42857143rem;
    line-height: 1.5
}

.h6, h6 {
    font-size: 1rem
}

.blockquote, blockquote, ol, p, pre, table, ul {
    margin-top: 0;
    margin-bottom: 1.42857rem
}

.blockquote:last-child, blockquote:last-child, ol:last-child, p:last-child, pre:last-child, table:last-child, ul:last-child {
    margin-bottom: 0
}

ol ol, ol ul, ul ol, ul ul {
    margin-top: 0;
    margin-bottom: 0
}

.hr, hr {
    border-top: 1px solid rgba(31, 32, 34, .1);
    margin: 1.42857rem 0
}

sub, sup {
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

a {
    color: #336cfb;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out
}

a, a:focus, a:hover {
    text-decoration: none
}

a:focus, a:hover {
    color: #2759fa;
    outline: none
}

img {
    height: auto;
    max-width: 100%
}

button {
    cursor: pointer
}

button:focus {
    outline: none
}

textarea {
    box-shadow: none
}

.mark, mark {
    padding: 0 .2em;
    background-color: #336cfb;
    color: #fff
}

::selection {
    background-color: #336cfb;
    color: #fff
}

::-moz-selection {
    background-color: #336cfb;
    color: #fff
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, .1)
}

::-webkit-scrollbar {
    background: hsla(0, 0%, 100%, .2);
    position: absolute;
    width: 2px
}

::-webkit-scrollbar:hover {
    display: block
}

::-webkit-scrollbar-thumb {
    background-color: #bcbcbd
}

::-webkit-scrollbar:horizontal {
    height: 2px
}

body, html {
    height: 100%
}

body {
    /*overflow: hidden;*/
    position: relative;
    width: 100%
}

.page-box {
    display: block;
    height: 100%
}

.page-box .app-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%
}

.page-box .app-container .main-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 4.28571rem;
    position: relative;
    -webkit-transition: padding .2s ease-in-out 0s;
    transition: padding .2s ease-in-out 0s;
    -webkit-overflow-scrolling: touch
}

.page-box .app-container .main-content .main-content-wrap {
    min-height: calc(100% - 2.85714rem);
    margin: 0 auto;
    opacity: 0;
    padding: 2.14286rem 1.42857rem 2.85714rem;
    -webkit-transition: opacity .2s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s
}

.page-box .app-container .main-content .main-content-wrap.full-filled {
    height: 100%;
    max-width: 100% !important;
    min-height: 100%;
    padding: 0
}

.page-box .app-container .main-content .main-content-wrap.full-filled .page-content {
    height: 100%
}

.page-box .app-container .main-content .main-content-wrap .page-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between
}

.page-box .app-container .main-content .main-content-wrap .page-header .page-title {
    margin: 0 0 2.14286rem
}

@media (max-width: 767px) {
    .page-box .app-container .main-content .main-content-wrap .page-header .page-title {
        font-size: 2rem
    }
}

.page-box .app-container .main-content .main-content-wrap .page-header .page-breadcrumbs {
    color: rgba(31, 32, 34, .5);
    font-size: .8rem;
    line-height: 2;
    margin: 0 0 1.42857rem
}

.page-box .app-container .main-content.loaded .main-content-wrap {
    opacity: 1;
    -webkit-transition: opacity 0s ease-in-out 0s;
    transition: opacity 0s ease-in-out 0s
}

.page-box .app-container .content-overlay {
    background: rgba(31, 32, 34, .1);
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out .2s;
    transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out .2s;
    visibility: hidden;
    width: 100%
}

.page-box .app-container .content-overlay.show {
    opacity: 1;
    -webkit-transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
    visibility: visible
}

@media (min-width: 992px) {
    .page-box .app-container .content-overlay.show {
        z-index: 9998
    }
}

body.vertical-layout .page-box .app-container {
    padding-left: 17.14286rem
}

@media (max-width: 991px) {
    body.vertical-layout .page-box .app-container {
        padding-left: 0
    }
}

body.vertical-layout .page-box .app-container .app-navbar.horizontal .navbar-skeleton .sk-logo {
    display: none !important
}

body.vertical-layout .page-box .app-container .app-footer {
    left: 17.14286rem
}

@media (max-width: 991px) {
    body.vertical-layout .page-box .app-container .app-footer {
        left: 0
    }
}

body.vertical-layout.scrolled .app-navbar.horizontal {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .05)
}

@media (max-width: 991px) {
    body.horizontal-layout .app-navbar.horizontal:not(.horizontal-vertical) .app-logo {
        display: none
    }
}

@media (min-width: 992px) {
    body.horizontal-layout .app-navbar.horizontal .app-logo .logo-wrap {
        padding-left: 0;
        padding-right: 0
    }
}

body.horizontal-layout .app-navbar.horizontal-vertical {
    background: #fbfbfb
}

@media (min-width: 992px) {
    body.horizontal-layout .app-navbar.horizontal-vertical .app-logo {
        display: none
    }
}

body.boxed .page-box .app-container .main-content .main-content-wrap {
    max-width: 1140px;
    max-width: var(--boxed-width)
}

body.public-layout .page-box .app-container {
    background-size: cover;
    display: block;
    height: 100%;
    overflow: auto;
    width: 100%
}

body.public-layout .page-box .app-container .content-box {
    background: #fff;
    box-shadow: 0 18px 24px rgba(0, 0, 0, .12);
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-width: 400px;
    min-height: 100%
}

body.public-layout .page-box .app-container .content-box .content-header {
    padding-top: 1.42857rem
}

body.public-layout .page-box .app-container .content-box .content-body {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding: .71429rem 2.85714rem;
    width: 100%
}

@media (max-width: 767px) {
    body.public-layout .page-box .app-container .content-box .content-body {
        padding-left: 1.42857rem;
        padding-right: 1.42857rem
    }
}

body.loaded .page-box .app-container .main-content .main-content-wrap {
    opacity: 1;
    -webkit-transition: opacity 0s ease-in-out 0s;
    transition: opacity 0s ease-in-out 0s
}

.assistant-menu {
    display: block;
    padding: 0 2.85714rem 1.42857rem
}

.assistant-menu .link {
    color: inherit;
    display: block;
    font-weight: 700;
    opacity: .3;
    padding: .35714rem 0;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.assistant-menu .link .link-icon {
    font-size: 1.3em;
    margin-right: .71429rem;
    padding: 0
}

.assistant-menu .link:hover {
    opacity: 1
}

@-webkit-keyframes loaderAnimation {
    0% {
        background-position: -468px 0
    }
    to {
        background-position: 468px 0
    }
}

.app-footer {
    background: #fbfbfb;
    border-top: 1px solid #e9e9e9;
    bottom: 0;
    display: block;
    height: 4.28571rem;
    left: 0;
    position: absolute;
    right: 0
}

.app-footer .footer-wrap {
    background: inherit;
    height: 100%;
    margin: 0 auto;
    padding: .71429rem 1.42857rem;
    position: relative
}

.app-footer .footer-wrap .page-breadcrumbs {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0
}

.app-footer .footer-wrap .page-breadcrumbs .item {
    display: block;
    vertical-align: top;
    white-space: nowrap
}

.app-footer .footer-wrap .page-breadcrumbs .item .link {
    color: currentColor;
    opacity: .5;
    text-decoration: none;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.app-footer .footer-wrap .page-breadcrumbs .item .link:hover {
    opacity: 1
}

.app-footer .footer-wrap .page-breadcrumbs .item .separator {
    margin: 0 .35714rem -2px
}

.app-footer .footer-wrap .page-breadcrumbs .item:last-child .link {
    opacity: 1;
    pointer-events: none
}

.app-footer .footer-wrap .page-breadcrumbs .item:last-child .separator {
    display: none
}

.app-footer .footer-wrap .settings-btn .icon {
    font-size: 26px
}

.app-footer .footer-wrap .footer-skeleton {
    background: inherit;
    height: 100%;
    left: 0;
    padding: .71429rem 1.42857rem;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
    z-index: 999
}

body.loaded .app-footer .footer-wrap .footer-skeleton {
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out .2s, visibility 0s ease-in-out .4s;
    transition: opacity .2s ease-in-out .2s, visibility 0s ease-in-out .4s;
    visibility: hidden
}

.app-footer .footer-wrap .footer-skeleton .row {
    height: inherit
}

.app-footer .footer-wrap .footer-skeleton .bg {
    background: #ebebeb
}

.app-footer .footer-wrap .footer-skeleton .bg-1 {
    background: #f5f5f5
}

.app-footer .footer-wrap .footer-skeleton .animated-bg {
    overflow: hidden;
    position: relative
}

.app-footer .footer-wrap .footer-skeleton .animated-bg:before {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: loaderAnimation;
    animation-name: loaderAnimation;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: transparent;
    background: -webkit-gradient(linear, left top, right top, color-stop(10%, transparent), color-stop(18%, hsla(0, 0%, 100%, .5)), color-stop(33%, transparent));
    background: linear-gradient(90deg, transparent 10%, hsla(0, 0%, 100%, .5) 18%, transparent 33%);
    background-size: 800px 100%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.app-footer .footer-wrap .footer-skeleton .page-breadcrumbs {
    margin: -.35714rem
}

.app-footer .footer-wrap .footer-skeleton .page-breadcrumbs .item {
    border-radius: 3px;
    display: block;
    height: .71429rem;
    margin: 0 .35714rem;
    width: 80px
}

.app-footer .footer-wrap .footer-skeleton .info {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    margin: -.35714rem
}

.app-footer .footer-wrap .footer-skeleton .info .version {
    border-radius: 3px;
    display: block;
    height: .71429rem;
    margin: 0 .35714rem;
    width: 80px
}

.app-footer .footer-wrap .footer-skeleton .info .settings {
    background: #336cfb;
    border-radius: 500px;
    display: block;
    height: 1.42857rem;
    margin: 0 .35714rem;
    width: 1.42857rem
}

.boxed .app-footer .footer-wrap {
    max-width: 1140px;
    max-width: var(--boxed-width)
}

.app-logo {
    display: block;
    height: 4.28571rem;
    min-height: 4.28571rem;
    position: relative
}

.app-logo .logo-wrap {
    -webkit-box-align: center;
    align-items: center;
    color: inherit;
    display: -webkit-box;
    display: flex;
    height: 100%;
    padding: .71429rem 2.85714rem;
    text-decoration: none
}

.app-logo .logo-wrap .logo-img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    vertical-align: top;
    width: auto
}

.app-logo .logo-wrap .logo-text {
    display: block;
    font-size: 20px;
    line-height: 1;
    margin-left: 1.42857rem
}

.main-menu {
    display: block;
    margin: 0;
    max-width: 100%;
    padding: 0
}

.main-menu, .main-menu .main-menu-wrap {
    width: inherit
}

.main-menu .main-menu-wrap .menu-ul {
    list-style-type: none;
    margin: 0;
    padding: 2.14286rem 1.42857rem 0
}

.main-menu .main-menu-wrap .menu-ul .menu-item {
    font-weight: 700;
    padding: 0 1.07143rem;
    position: relative
}

.main-menu .main-menu-wrap .menu-ul .menu-item .group-title {
    color: currentColor;
    display: block;
    opacity: .3;
    padding: 2.14286rem .35714rem .71429rem;
    text-transform: uppercase
}

.main-menu .main-menu-wrap .menu-ul .menu-item:first-child .group-title {
    padding-top: 0
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link {
    -webkit-box-align: center;
    align-items: center;
    background: transparent;
    color: currentColor;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 2.14286rem;
    padding: .35714rem 0;
    position: relative;
    text-decoration: none;
    -webkit-transition: background .2s ease-in-out, color .2s ease-in-out;
    transition: background .2s ease-in-out, color .2s ease-in-out
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-text {
    -webkit-box-flex: 2;
    flex-grow: 2;
    flex-shrink: 1;
    margin: 0 .35714rem
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-badge {
    margin: 0 .35714rem
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-icon {
    font-size: 1.3em;
    margin: 0 .35714rem;
    opacity: .25;
    padding: 0;
    -webkit-transition: none;
    transition: none
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-caret {
    margin: 0 .35714rem;
    -webkit-transition: -webkit-transform .1s ease-in-out;
    transition: -webkit-transform .1s ease-in-out;
    transition: transform .1s ease-in-out;
    transition: transform .1s ease-in-out, -webkit-transform .1s ease-in-out
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link:hover {
    background: transparent;
    color: #336cfb
}

.main-menu .main-menu-wrap .menu-ul .menu-item .sub {
    background: transparent;
    max-height: 0;
    list-style-type: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
    visibility: hidden
}

.main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item {
    font-weight: 400;
    padding-left: .35714rem;
    padding-right: .35714rem
}

.main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item:after {
    display: none
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active:after {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
    -webkit-transition: -webkit-transform .2s ease-in-out .2s;
    transition: -webkit-transform .2s ease-in-out .2s;
    transition: transform .2s ease-in-out .2s;
    transition: transform .2s ease-in-out .2s, -webkit-transform .2s ease-in-out .2s
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active > .item-link {
    color: #336cfb
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active > .item-link .link-caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active > .sub {
    max-height: 1000px;
    visibility: visible
}

.main-menu .main-menu-wrap .menu-ul .menu-item.has-sub, .main-menu .main-menu-wrap .menu-ul .menu-item.has-sub > .item-link {
    position: relative
}

.main-menu .main-menu-wrap .menu-ul .menu-item.has-sub > .item-link .caret {
    display: block
}

.main-menu .main-menu-wrap .menu-ul .menu-item.has-sub.active > .item-link .caret {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

@media (min-width: 992px) {
    .app-navbar.horizontal-vertical .main-menu {
        margin: 0 !important;
        overflow: visible
    }
}

@media (min-width: 992px) {
    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .mCustomScrollBox, .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .mCustomScrollBox .mCSB_container {
        overflow: visible
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .mCSB_scrollTools {
        display: none !important
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        padding: 0
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul > .menu-item > .group-title {
        display: none
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item {
        padding: 0
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .item-link {
        padding: .71429rem
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-caret {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub {
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 18px 24px rgba(0, 0, 0, .12);
        display: block !important;
        color: #000;
        height: auto !important;
        left: 0;
        max-height: none;
        opacity: 0;
        padding: .71429rem 0;
        position: absolute;
        -webkit-transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out .2s;
        transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out .2s;
        visibility: hidden !important;
        width: 17.85714rem;
        z-index: 500
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub:before {
        bottom: 2.85714rem
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item {
        width: 100%
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item .item-link {
        height: auto;
        min-height: 0;
        padding-top: .35714rem;
        padding-bottom: .35714rem
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item:hover > .sub {
        opacity: 1;
        -webkit-transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
        transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
        visibility: visible !important
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item:last-child .sub {
        left: auto;
        right: 0
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item.full-sub {
        position: static
    }

    .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item.full-sub .sub {
        width: 100%
    }
}

@keyframes loaderAnimation {
    0% {
        background-position: -468px 0
    }
    to {
        background-position: 468px 0
    }
}

.app-navbar {
    background: var(--navbar-bg);
    color: var(--navbar-color);
    display: block;
    min-height: 4.28571rem;
    -webkit-transition: background .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
    transition: background .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
    will-change: box-shadow
}

.app-navbar .navbar-wrap {
    -webkit-box-align: center;
    align-items: center;
    background: inherit;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: 0 .71429rem;
    position: relative
}

.app-navbar .navbar-wrap > * {
    margin: 0 .71429rem
}

.app-navbar .navbar-wrap .navbar-toggle {
    -webkit-box-align: center;
    align-items: center;
    color: inherit;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 40px;
    -webkit-box-pack: center;
    justify-content: center;
    width: 35px
}

.app-navbar .navbar-wrap .navbar-toggle span {
    background: #1f2022;
    display: block;
    height: 3px;
    margin: 4px 0;
    width: 35px
}

.app-navbar .navbar-wrap .navbar-close {
    color: inherit;
    cursor: pointer;
    font-size: 30px;
    height: auto;
    position: absolute;
    right: .71429rem;
    top: 1.07143rem;
    width: auto !important;
    z-index: 1
}

.app-navbar .navbar-wrap .add-patient {
    height: 100%;
    padding: 1.42857rem 2.85714rem
}

.app-navbar .navbar-wrap .assistant-menu {
    display: block;
    padding: 0 2.85714rem 1.42857rem
}

.app-navbar .navbar-wrap .assistant-menu .link {
    color: inherit;
    display: block;
    font-weight: 700;
    opacity: .3;
    padding: .35714rem 0;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.app-navbar .navbar-wrap .assistant-menu .link .tc-icon {
    margin-right: .71429rem;
    padding: 0
}

.app-navbar .navbar-wrap .assistant-menu .link:hover {
    opacity: 1
}

body.boxed .app-navbar .navbar-wrap {
    max-width: 1140px;
    max-width: var(--boxed-width)
}

@media (max-width: 991px) {
    .app-navbar.horizontal-vertical {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transform: translateX(-17.14286rem);
        transform: translateX(-17.14286rem);
        -webkit-transition: -webkit-transform .2s ease-in-out;
        transition: -webkit-transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
        width: 17.14286rem;
        will-change: transform, width;
        z-index: 9997
    }

    .app-navbar.horizontal-vertical .navbar-wrap {
        -webkit-box-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        height: 100%;
        -webkit-box-pack: start;
        justify-content: flex-start;
        overflow: auto;
        padding: 0;
        width: 17.14286rem
    }

    .app-navbar.horizontal-vertical .navbar-wrap > * {
        margin: 0;
        width: 100%
    }

    .app-navbar.horizontal-vertical.opened {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@media (min-width: 992px) {
    .app-navbar.horizontal-vertical {
        min-height: 2.85714rem
    }
}

.app-navbar.vertical {
    background: var(--navbar-2-bg);
    color: var(--navbar-2-color);
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    width: 17.14286rem;
    will-change: transform, width;
    -webkit-overflow-scrolling: touch;
    z-index: 9997
}

@media (max-width: 991px) {
    .app-navbar.vertical {
        -webkit-transform: translateX(-17.14286rem);
        transform: translateX(-17.14286rem)
    }
}

.app-navbar.vertical .navbar-wrap {
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0;
    width: 17.14286rem
}

.app-navbar.vertical .navbar-wrap > * {
    margin: 0;
    width: 100%
}

.app-navbar.vertical.opened {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.app-navbar.dark {
    background: var(--navbar-dark-bg);
    color: var(--navbar-dark-color)
}

.app-navbar .navbar-skeleton {
    -webkit-box-align: center;
    align-items: center;
    background: inherit;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    left: 0;
    overflow: hidden;
    padding: 0 .71429rem;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s, visibility 0s ease-in-out 0s;
    z-index: 999
}

body.loaded .app-navbar .navbar-skeleton {
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out .2s, visibility 0s ease-in-out .4s;
    transition: opacity .2s ease-in-out .2s, visibility 0s ease-in-out .4s;
    visibility: hidden
}

.app-navbar .navbar-skeleton .bg {
    background: #ebebeb
}

.app-navbar .navbar-skeleton .bg-1 {
    background: #f5f5f5
}

.app-navbar .navbar-skeleton .left-part {
    -webkit-box-flex: 1;
    flex-grow: 1
}

.app-navbar .navbar-skeleton .navbar-button {
    display: block;
    height: 30px;
    margin-right: 1.42857rem;
    width: 35px
}

.app-navbar .navbar-skeleton .search {
    border-radius: 20px;
    display: block;
    height: 40px;
    max-width: 100%;
    width: 350px
}

.app-navbar .navbar-skeleton .icon-box {
    position: relative
}

.app-navbar .navbar-skeleton .icon-box .icon {
    border-radius: 5px;
    display: block;
    height: 20px;
    width: 20px
}

.app-navbar .navbar-skeleton .icon-box .badge {
    background: #ed5564;
    border: 0;
    border-radius: 20px;
    display: block;
    height: 16px;
    padding: 0;
    position: absolute;
    right: -8px;
    top: -10px;
    width: 16px
}

.app-navbar .navbar-skeleton .avatar {
    border-radius: 20px;
    display: block;
    height: 40px;
    margin: 0 18px 0 25px;
    width: 40px
}

.app-navbar .navbar-skeleton .sk-logo {
    border-radius: 5px;
    display: block;
    height: 2.85714rem;
    margin-right: 1.42857rem;
    max-width: 169px;
    width: 100%
}

.app-navbar .navbar-skeleton .animated-bg {
    overflow: hidden;
    position: relative
}

.app-navbar .navbar-skeleton .animated-bg:before {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: loaderAnimation;
    animation-name: loaderAnimation;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: transparent;
    background: -webkit-gradient(linear, left top, right top, color-stop(10%, transparent), color-stop(18%, hsla(0, 0%, 100%, .5)), color-stop(33%, transparent));
    background: linear-gradient(90deg, transparent 10%, hsla(0, 0%, 100%, .5) 18%, transparent 33%);
    background-size: 800px 100%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.app-navbar .navbar-skeleton.vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column
}

.app-navbar .navbar-skeleton.vertical .bottom-part, .app-navbar .navbar-skeleton.vertical .top-part {
    padding: .71429rem 2.14286rem;
    width: 100%
}

.app-navbar .navbar-skeleton.vertical .bottom-part {
    padding-bottom: 2.14286rem
}

.app-navbar .navbar-skeleton.vertical .sk-logo {
    margin: 0
}

.app-navbar .navbar-skeleton.vertical .sk-menu {
    margin: 3.21429rem 0 0
}

.app-navbar .navbar-skeleton.vertical .sk-menu .sk-menu-item {
    border-radius: 3px;
    display: block;
    height: 1.07143rem;
    margin: 1.07143rem 0 0
}

.app-navbar .navbar-skeleton.vertical .sk-menu .sk-menu-item.menu-header {
    width: 50%
}

.app-navbar .navbar-skeleton.vertical .sk-button {
    background: #336cfb;
    border-radius: 6px;
    height: 2.85714rem;
    margin-top: 2.14286rem
}

.app-navbar .navbar-skeleton.vertical .horizontal-menu {
    -webkit-box-align: center;
    align-items: center;
    align-self: flex-start;
    display: none;
    height: 100%;
    margin: 0 .71429rem
}

.app-navbar .navbar-skeleton.vertical .horizontal-menu .sk-menu-item {
    border-radius: 3px;
    display: block;
    height: .71429rem;
    margin: 0 .71429rem 0 0;
    width: 5.71429rem
}

@media (min-width: 992px) {
    .app-navbar.horizontal-vertical .navbar-skeleton.vertical .bottom-part, .app-navbar.horizontal-vertical .navbar-skeleton.vertical .top-part {
        display: none
    }

    .app-navbar.horizontal-vertical .navbar-skeleton.vertical .horizontal-menu {
        display: -webkit-box;
        display: flex
    }
}

.app-search {
    display: block;
    max-width: 25rem;
    width: 50%
}

.app-search .typeahead__query {
    z-index: auto
}

.app-actions {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 2;
    flex-grow: 2;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin: 0 !important
}

.app-actions .item {
    margin: 0 .71429rem
}

.app-actions .item .dropdown-toggle {
    color: inherit;
    display: block
}

.app-actions .item .dropdown-toggle .icon {
    font-size: 26px;
    opacity: .5;
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, color .2s ease-in-out, opacity .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, color .2s ease-in-out, opacity .2s ease-in-out
}

.app-actions .item .dropdown-toggle:after {
    display: none
}

.app-actions .item .dropdown-toggle .badge {
    border: 2px solid #fff;
    -webkit-box-pack: center;
    justify-content: center;
    min-width: 21px;
    padding-left: 2px;
    padding-right: 2px;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px
}

.app-actions .item .dropdown-toggle:hover .icon {
    opacity: 1
}

.app-actions .item .dropdown-menu {
    z-index: 9999
}

.app-actions .item.show .dropdown-toggle .icon {
    color: #336cfb;
    opacity: 1
}

.alert {
    border: 1px solid transparent;
    border-radius: 6px;
    position: relative;
    padding: calc(1.42858rem - 1px)
}

.alert .title {
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: .35714rem
}

.alert .close {
    align-self: flex-start;
    color: inherit;
    cursor: pointer;
    font-size: 1.25em;
    line-height: 1;
    margin: .71429rem;
    padding: 0;
    opacity: 0;
    text-shadow: none;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out
}

.alert .alert-icon {
    -webkit-box-align: center;
    align-items: center;
    background: hsla(0, 0%, 100%, .2);
    display: -webkit-box;
    display: flex;
    font-size: 1.43em;
    line-height: 1;
    min-height: 100%;
    padding: .71429rem 1.07143rem
}

.alert.alert-secondary {
    background: hsla(0, 0%, 92.2%, .3);
    color: inherit;
    border-color: hsla(0, 0%, 92.2%, .3)
}

.alert.alert-secondary.outline {
    background: transparent;
    color: hsla(0, 0%, 92.2%, .3)
}

.alert.alert-primary {
    background: #336cfb;
    color: #fff;
    border-color: #336cfb
}

.alert.alert-primary.outline {
    background: transparent;
    color: #336cfb
}

.alert.alert-success {
    background: #b7ce63;
    color: #000;
    border-color: #b7ce63
}

.alert.alert-success.outline {
    background: transparent;
    color: #b7ce63
}

.alert.alert-info {
    background: #64b5f6;
    color: #000;
    border-color: #64b5f6
}

.alert.alert-info.outline {
    background: transparent;
    color: #64b5f6
}

.alert.alert-warning {
    background: #e9e165;
    color: #000;
    border-color: #e9e165
}

.alert.alert-warning.outline {
    background: transparent;
    color: #e9e165
}

.alert.alert-danger {
    background: #ed5564;
    color: #fff;
    border-color: #ed5564
}

.alert.alert-danger.outline {
    background: transparent;
    color: #ed5564
}

.alert.alert-light {
    background: #e4e4e4;
    color: #000;
    border-color: #e4e4e4
}

.alert.alert-light.outline {
    background: transparent;
    color: #e4e4e4
}

.alert.alert-dark {
    background: #1f2022;
    color: #fff;
    border-color: #1f2022
}

.alert.alert-dark.outline {
    background: transparent;
    color: #1f2022
}

.alert.with-after-icon, .alert.with-before-icon {
    display: -webkit-box;
    display: flex;
    padding: 0 .71429rem
}

.alert.with-after-icon .alert-content, .alert.with-before-icon .alert-content {
    -webkit-box-flex: 1;
    flex: 1;
    padding: calc(1.42858rem - 1px) .71429rem
}

.alert.with-before-icon {
    padding-left: 0
}

.alert.with-after-icon {
    padding-right: 0
}

.alert:hover .close {
    opacity: 1
}

.badge {
    -webkit-box-align: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 500px;
    display: -webkit-inline-box;
    display: inline-flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.43;
    padding: .35715rem calc(1.42858rem - 1px);
    vertical-align: top
}

.badge.badge-xs {
    padding: 0 calc(.35715rem - 1px)
}

.badge.badge-sm, .badge.badge-xs {
    font-size: .7rem;
    line-height: 1.53061
}

.badge.badge-sm {
    padding: calc(.17857rem - 1px) calc(.71429rem - 1px)
}

.badge.badge-lg {
    font-size: 1.2rem;
    line-height: 1.19048;
    padding: calc(.71429rem - 1px) calc(2.14287rem - 1px)
}

.badge.badge-inside {
    font-size: 75%;
    padding: .1em 1em
}

.badge.badge-primary {
    background: #336cfb;
    border-color: #336cfb;
    color: #fff
}

.badge.badge-primary.badge-pill {
    background: transparent !important;
    color: #336cfb
}

.badge.badge-secondary {
    background: #626364;
    border-color: #626364;
    color: #fff
}

.badge.badge-secondary.badge-pill {
    background: transparent !important;
    color: #626364
}

.badge.badge-success {
    background: #b7ce63;
    border-color: #b7ce63;
    color: #000
}

.badge.badge-success.badge-pill {
    background: transparent !important;
    color: #b7ce63
}

.badge.badge-info {
    background: #64b5f6;
    border-color: #64b5f6;
    color: #000
}

.badge.badge-info.badge-pill {
    background: transparent !important;
    color: #64b5f6
}

.badge.badge-warning {
    background: #e9e165;
    border-color: #e9e165;
    color: #000
}

.badge.badge-warning.badge-pill {
    background: transparent !important;
    color: #e9e165
}

.badge.badge-error {
    background: #ed5564;
    border-color: #ed5564;
    color: #fff
}

.badge.badge-error.badge-pill {
    background: transparent !important;
    color: #ed5564
}

.badge.badge-light {
    background: #e4e4e4;
    border-color: #e4e4e4;
    color: #000
}

.badge.badge-light.badge-pill {
    background: transparent !important;
    color: #e4e4e4
}

.badge.badge-dark {
    background: #0a0b0c;
    border-color: #0a0b0c;
    color: #fff
}

.badge.badge-dark.badge-pill {
    background: transparent !important;
    color: #0a0b0c
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.btn {
    -webkit-box-align: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    box-shadow: 0 18px 24px rgba(0, 0, 0, .12);
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: Mulish, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1.42857;
    outline: none;
    padding: calc(.71429rem - .5px) 1.42857rem;
    position: relative;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out, opacity .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out, opacity .2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    will-change: background, border, box-shadow, color, opacity;
    -webkit-appearance: none
}

.btn:hover {
    border-color: transparent;
    box-shadow: 0 8px 24px rgba(0, 0, 0, .12)
}

.btn .btn-icon {
    font-size: 1.3em;
    margin: 0
}

.btn .btn-loader {
    -webkit-box-align: center;
    align-items: center;
    background: inherit;
    border-radius: inherit;
    display: -webkit-box;
    display: flex;
    font-size: 150%;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%
}

.btn .btn-loader:before {
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite
}

.btn.btn-square {
    min-width: 2.85714rem
}

.btn.btn-square, .btn.btn-square.btn-sm {
    padding-left: .35714rem;
    padding-right: .35714rem
}

.btn.btn-square.btn-sm {
    min-width: 2.5rem
}

.btn.btn-square.btn-lg {
    min-width: 3.57143rem;
    padding-left: .71429rem;
    padding-right: .71429rem
}

.btn.btn-lg {
    font-size: 1.2rem;
    line-height: 1.19048;
    padding: calc(1.07143rem - .5px) 2.14286rem
}

.btn.btn-sm {
    font-size: .8rem;
    line-height: .89286;
    padding: calc(.71429rem - .5px) .71429rem
}

.btn.btn-primary {
    background: #336cfb;
    border-color: #336cfb;
    color: #fff
}

.btn.btn-primary:hover {
    background: #2759fa;
    color: #fff
}

.btn.btn-primary.btn-outline {
    border-color: #336cfb;
    color: #336cfb
}

.active .btn.btn-primary.btn-outline, .btn.btn-primary.btn-outline:hover {
    background: #336cfb;
    color: #fff
}

.btn.btn-secondary {
    background: #626364;
    border-color: #626364;
    color: #fff
}

.btn.btn-secondary:hover {
    background: #1f2022;
    color: #fff
}

.btn.btn-secondary.btn-outline {
    border-color: #626364;
    color: #626364
}

.active .btn.btn-secondary.btn-outline, .btn.btn-secondary.btn-outline:hover {
    background: #626364;
    color: #fff
}

.btn.btn-success {
    background: #b7ce63;
    border-color: #b7ce63;
    color: #000
}

.btn.btn-success:hover {
    background: #a5c13d;
    color: #000
}

.btn.btn-success.btn-outline {
    border-color: #b7ce63;
    color: #b7ce63
}

.active .btn.btn-success.btn-outline, .btn.btn-success.btn-outline:hover {
    background: #b7ce63;
    color: #000
}

.btn.btn-info {
    background: #64b5f6;
    border-color: #64b5f6;
    color: #000
}

.btn.btn-info:hover {
    background: #349ef3;
    color: #000
}

.btn.btn-info.btn-outline {
    border-color: #64b5f6;
    color: #64b5f6
}

.active .btn.btn-info.btn-outline, .btn.btn-info.btn-outline:hover {
    background: #64b5f6;
    color: #000
}

.btn.btn-warning {
    background: #e9e165;
    border-color: #e9e165;
    color: #000
}

.btn.btn-warning:hover {
    background: #e3d838;
    color: #000
}

.btn.btn-warning.btn-outline {
    border-color: #e9e165;
    color: #e9e165
}

.active .btn.btn-warning.btn-outline, .btn.btn-warning.btn-outline:hover {
    background: #e9e165;
    color: #000
}

.btn.btn-error {
    background: #ed5564;
    border-color: #ed5564;
    color: #fff
}

.btn.btn-error:hover {
    background: #e8273a;
    color: #e6e6e6
}

.btn.btn-error.btn-outline {
    border-color: #ed5564;
    color: #ed5564
}

.active .btn.btn-error.btn-outline, .btn.btn-error.btn-outline:hover {
    background: #ed5564;
    color: #fff
}

.btn.btn-outline {
    background: transparent
}

.btn.disabled, .btn:disabled {
    opacity: .6
}

.btn.btn-load, .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    pointer-events: none
}

.btn.btn-load .btn-icon, .btn.btn-load .btn-text {
    visibility: hidden
}

.btn.btn-load .btn-loader {
    visibility: visible
}

.btn.btn-icon-animation .btn-icon {
    -webkit-box-align: center;
    align-items: center;
    background: inherit;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: scale(0);
    transform: scale(0);
    will-change: transform
}

.active .btn.btn-icon-animation .btn-icon, .btn.btn-icon-animation:hover .btn-icon {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.card {
    background: transparent 50% 50% no-repeat;
    background-size: cover;
    border: 0;
    border-radius: 6px;
    display: block;
    margin-bottom: 2.85714rem;
    position: relative
}

.card .card-img {
    width: 100%
}

.card .card-img, .card .card-img-top {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.card .card-img-overlay {
    overflow: auto
}

.card .card-header {
    background: none;
    border: 0;
    font-size: 2rem;
    font-weight: 500;
    margin: 0;
    padding: 0
}

.card .card-body {
    display: block;
    padding: 1.42857rem 0 0;
    position: relative;
    width: 100%
}

.card .card-body:first-child {
    padding-top: 0
}

.card .overlay-img {
    border-radius: inherit
}

.card img {
    max-width: 100%;
    height: auto
}

.card.bg-danger, .card.bg-dark, .card.bg-info, .card.bg-light, .card.bg-primary, .card.bg-secondary, .card.bg-success, .card.bg-warning, .card.border-danger, .card.border-dark, .card.border-info, .card.border-light, .card.border-primary, .card.border-secondary, .card.border-success, .card.border-warning {
    box-shadow: 0 18px 24px rgba(0, 0, 0, .12)
}

.card.bg-danger .card-header, .card.bg-dark .card-header, .card.bg-info .card-header, .card.bg-light .card-header, .card.bg-primary .card-header, .card.bg-secondary .card-header, .card.bg-success .card-header, .card.bg-warning .card-header, .card.border-danger .card-header, .card.border-dark .card-header, .card.border-info .card-header, .card.border-light .card-header, .card.border-primary .card-header, .card.border-secondary .card-header, .card.border-success .card-header, .card.border-warning .card-header {
    padding: .71429rem 1.42857rem 0
}

.card.bg-danger .card-body, .card.bg-dark .card-body, .card.bg-info .card-body, .card.bg-light .card-body, .card.bg-primary .card-body, .card.bg-secondary .card-body, .card.bg-success .card-body, .card.bg-warning .card-body, .card.border-danger .card-body, .card.border-dark .card-body, .card.border-info .card-body, .card.border-light .card-body, .card.border-primary .card-body, .card.border-secondary .card-body, .card.border-success .card-body, .card.border-warning .card-body {
    padding: 1.42857rem
}

.card.border-danger, .card.border-dark, .card.border-info, .card.border-light, .card.border-primary, .card.border-secondary, .card.border-success, .card.border-warning {
    background: none;
    border-style: solid;
    border-width: 1px
}

.custom-control {
    padding-left: 2.14286rem;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out
}

.form-check-label {
    font-weight: 500
}

.custom-checkbox .custom-control-label {
    color: inherit;
    cursor: pointer;
    font-weight: 500
}

.custom-checkbox .custom-control-label:after, .custom-checkbox .custom-control-label:before {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 3px;
    box-shadow: none !important;
    display: -webkit-box;
    display: flex;
    height: 1.42857rem;
    -webkit-box-pack: center;
    justify-content: center;
    left: -2.14286rem;
    margin: 0;
    outline: none !important;
    padding: 0;
    top: 0;
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, transform .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    width: 1.42857rem
}

.custom-checkbox .custom-control-label:before {
    background: #ebebeb;
    border: 1px solid #ebebeb;
    content: "";
    pointer-events: auto
}

.custom-checkbox .custom-control-label:after {
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    color: #fff;
    content: "\eed6";
    direction: ltr;
    font-family: IcoFont !important;
    font-size: 1.6rem;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    pointer-events: none;
    speak: none;
    text-transform: none;
    -webkit-transform: scale(0);
    transform: scale(0);
    white-space: nowrap;
    word-wrap: normal
}

.custom-checkbox .custom-control-label:hover {
    color: inherit
}

.custom-checkbox .custom-control-label:hover:before {
    background: #ebebeb;
    border-color: #ebebeb
}

.custom-checkbox .custom-control-label:hover:after {
    color: #336cfb;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background: #336cfb;
    border-color: #336cfb
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: none !important;
    color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: #bdbdbd;
    cursor: not-allowed
}

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
    background: hsla(0, 0%, 80%, .1);
    border-color: hsla(0, 0%, 80%, .5)
}

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label:after {
    color: inherit;
    -webkit-transform: scale(0);
    transform: scale(0)
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before {
    background: hsla(0, 0%, 80%, .1);
    border-color: hsla(0, 0%, 80%, .5)
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:after {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.contact {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 18px 24px rgba(0, 0, 0, .12);
    display: block;
    margin: 0 auto 1.42857rem;
    max-width: 400px;
    text-align: center;
    -webkit-transition: box-shadow .2s ease-in-out;
    transition: box-shadow .2s ease-in-out
}

.contact:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, .12)
}

.contact .img-box {
    background: rgba(0, 0, 0, .05);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden
}

.contact .info-box {
    padding: 1.42857rem 1.42857rem 2.14286rem
}

.contact .info-box .name {
    margin: 0 0 .71429rem
}

.contact .info-box .role {
    opacity: .6
}

.contact .info-box .social {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 -.35714rem 1.42857rem
}

.contact .info-box .social .link {
    color: inherit;
    display: block;
    font-size: 1.43rem;
    margin: 0 .35714rem
}

.contact .info-box .social .link:hover {
    color: #336cfb
}

.contact .info-box .address {
    opacity: .6
}

.dropdown-toggle-split {
    padding-left: .71429rem;
    padding-right: .71429rem
}

.dropdown-menu {
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 18px 24px rgba(0, 0, 0, .12);
    margin: 0;
    padding: .71429rem 0
}

.dropdown-menu .menu-list {
    font-size: 13px;
    list-style-type: none;
    margin: 0;
    padding: 10px 0
}

.dropdown-menu .menu-list li a {
    color: rgba(51, 51, 51, .65);
    display: block;
    padding: 5px 15px;
    text-decoration: none
}

.dropdown-menu .menu-list li a:hover {
    color: #333
}

.dropdown-menu .menu-footer, .dropdown-menu .menu-header {
    margin: 0;
    padding: .71429rem 1.42857rem
}

.dropdown-menu .menu-header {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 6px 6px 0 0;
    color: #1f2022;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between
}

.dropdown-menu .menu-footer {
    border-radius: 0 0 6px 6px
}

.dropdown-menu .list {
    list-style-type: none;
    margin: 0;
    max-height: 280px;
    overflow: auto;
    padding: 0
}

.dropdown-menu .list > li > a {
    color: #1f2022;
    color: var(--main-color);
    display: -webkit-box;
    display: flex;
    padding: .71429rem 1.42857rem;
    text-decoration: none;
    -webkit-transition: background .2s ease-in-out, color .2s ease-in-out;
    transition: background .2s ease-in-out, color .2s ease-in-out
}

.dropdown-menu .list > li > a .avatar, .dropdown-menu .list > li > a .icon, .dropdown-menu .list > li > a .tc-icon {
    align-self: flex-start;
    margin-right: .71429rem
}

.dropdown-menu .list > li > a .icon {
    background: rgba(31, 32, 34, .1);
    border-radius: 50%;
    font-size: 1rem;
    height: auto;
    padding: .71429rem;
    -webkit-transition: background .2s ease-in-out;
    transition: background .2s ease-in-out;
    width: auto
}

.dropdown-menu .list > li > a .avatar {
    border-radius: 50%;
    height: 40px;
    width: 40px
}

.dropdown-menu .list > li > a .content {
    font-size: .85rem
}

.dropdown-menu .list > li > a .content .desc {
    display: block
}

.dropdown-menu .list > li > a .content .date, .dropdown-menu .list > li > a .content .size {
    display: block;
    font-size: .75rem;
    margin-top: 4px;
    opacity: .6
}

.dropdown-menu .list > li > a .content .meta {
    -webkit-box-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    line-height: 1.3;
    margin-bottom: 4px
}

.dropdown-menu .list > li > a .content .meta .date {
    margin: 0 0 0 11px
}

.dropdown-menu .list > li > a:hover {
    color: #336cfb
}

.dropdown-menu .list > li > a:hover .icon {
    background: #336cfb;
    color: #fff
}

.dropdown-menu .list > li:first-child > a {
    border-top: 0
}

.dropdown-menu .list > li .empty-item {
    display: block;
    padding: .71429rem 1.42857rem
}

.dropdown-menu .author-info {
    padding: 1.42857rem .71429rem 0;
    text-align: center
}

.dropdown-menu .author-info .author-name {
    font-size: 1rem
}

.dropdown-menu .dropdown-overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity .1s ease-in-out .2s;
    transition: opacity .1s ease-in-out .2s;
    z-index: 9997
}

.dropdown-menu .dropdown-overlay .overlay-bg {
    background: rgba(31, 32, 34, .1);
    display: block;
    height: 100%;
    width: 100%
}

.dropdown-menu.dropdown-menu-w-180 {
    width: 180px
}

.dropdown-menu.dropdown-menu-w-280 {
    width: 280px
}

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both
}

.form-control {
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    color: #1f2022;
    display: block;
    font-size: 1rem;
    height: auto;
    line-height: 1.43;
    outline: none;
    padding: calc(.71429rem - 1px) 1.42857rem;
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
    will-change: background, border, box-shadow, color;
    width: 100%
}

.form-control ::-webkit-input-placeholder {
    color: #a8a8a8
}

.form-control :-moz-placeholder, .form-control ::-moz-placeholder {
    color: #a8a8a8
}

.form-control :-ms-input-placeholder {
    color: #a8a8a8
}

.form-control:hover {
    background: #ebebeb;
    border-color: #ebebeb
}

.form-control:focus, .form-control:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
    color: #1f2022
}

.form-control:focus {
    background: #fff;
    border-color: #336cfb
}

.form-control .tc-input-wrap .input-prefix, .form-control .tc-input-wrap .input-suffix {
    color: #a8a8a8;
    -webkit-box-flex: 0;
    flex: none;
    padding: 0 .2em
}

.form-control .tc-input-wrap .input-icon {
    font-size: 1.3em
}

.form-control .tc-input-wrap .char-limiting {
    -webkit-box-align: center;
    align-items: center;
    color: #a8a8a8;
    display: -webkit-box;
    display: flex;
    font-size: .9em;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0 .2em;
    pointer-events: none
}

.form-control.ng-invalid.ng-touched .tc-input-wrap, .has-error .form-control .tc-input-wrap {
    background-color: rgba(237, 85, 100, .1);
    border: 1px solid #ed5564
}

.form-control.form-control-sm {
    border-radius: 16px;
    font-size: .9rem;
    height: 2.14286rem;
    line-height: 1.5873;
    padding-top: .35714rem;
    padding-bottom: .35714rem
}

.form-control.form-control-sm .input-icon {
    font-size: 1.1em
}

.form-control.form-control-lg {
    border-radius: 30px;
    font-size: 1.3rem;
    height: 3.57143rem;
    line-height: 1.0989;
    padding-top: 1.07143rem;
    padding-bottom: 1.07143rem
}

.form-control.disabled, .form-control:disabled, .form-control[readonly] {
    background-color: hsla(0, 0%, 80%, .1);
    border-color: hsla(0, 0%, 80%, .5);
    box-shadow: none !important;
    color: #bdbdbd;
    cursor: not-allowed
}

.form-control-plaintext {
    color: #1f2022;
    font-size: 1rem;
    height: 2.85714rem;
    line-height: 1.43;
    padding-top: calc(.71429rem - 1px);
    padding-bottom: calc(.71429rem - 1px)
}

.form-control-plaintext.form-control-sm {
    font-size: .9rem;
    height: 2.14286rem;
    line-height: 1.5873;
    padding-top: .35714rem;
    padding-bottom: .35714rem
}

.form-control-plaintext.form-control-lg {
    font-size: 1.3rem;
    height: 3.57143rem;
    line-height: 1.0989;
    padding-top: 1.07143rem;
    padding-bottom: 1.07143rem
}

.form-group {
    margin-bottom: 1.42857rem;
    position: relative
}

.form-text {
    display: block;
    margin: .71429rem 0 0
}

label {
    font-weight: 600;
    margin: 0 0 .71429rem
}

.input-group-text {
    background: #ebebeb;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    padding-left: 1.42857rem;
    padding-right: 1.42857rem
}

.input-group .btn {
    border-radius: 20px
}

.input-group .typeahead__cancel-button {
    z-index: 1
}

.input-group .typeahead__cancel-button + .form-control {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px
}

.input-group .input-group-prepend ~ .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.with-prefix-icon, .with-suffix-icon {
    position: relative
}

.with-prefix-icon .prefix-icon, .with-prefix-icon .suffix-icon, .with-suffix-icon .prefix-icon, .with-suffix-icon .suffix-icon {
    -webkit-box-align: center;
    align-items: center;
    color: #a8a8a8;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 2.85714rem
}

.with-prefix-icon .prefix-icon, .with-suffix-icon .prefix-icon {
    left: 0
}

.with-prefix-icon .suffix-icon, .with-suffix-icon .suffix-icon {
    right: 0
}

.with-prefix-icon .form-control {
    padding-left: 2.85714rem
}

.with-suffix-icon .form-control {
    padding-right: 2.85714rem
}

textarea.form-control {
    min-height: 2.85714rem;
    resize: none
}

textarea.form-control.resize {
    resize: vertical
}

.form-control.is-invalid, .form-control.is-valid, .was-validated .form-control:invalid, .was-validated .form-control:valid {
    background-position: top 50% right .71429rem
}

.was-validated textarea.form-control:invalid, .was-validated textarea.form-control:valid, textarea.form-control.is-invalid, textarea.form-control.is-valid {
    background-position: top .71429rem right .71429rem
}

.modal {
    z-index: 9999
}

.modal-content {
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 18px 24px rgba(0, 0, 0, .12)
}

.modal-content .close {
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 1000
}

.modal-content .modal-header {
    -webkit-box-align: center;
    align-items: center;
    border: 0;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 1.42857rem 1.07143rem
}

.modal-content .modal-header > * {
    margin: 0 .35714rem
}

.modal-content .modal-header .modal-title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1
}

.modal-content .modal-body {
    padding: 1.42857rem
}

.modal-content .modal-footer {
    background: rgba(0, 0, 0, .05);
    border: 0;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 1.42857rem
}

.modal-content .modal-footer .actions {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: -.35714rem
}

.modal-content .modal-footer .actions > * {
    margin: .35714rem
}

.modal-dialog-bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
    min-height: calc(100% - 3.5rem)
}

.modal-backdrop {
    background: rgba(0, 0, 0, .1);
    z-index: 9998
}

.custom-radio .custom-control-label {
    color: inherit;
    cursor: pointer;
    font-weight: 500
}

.custom-radio .custom-control-label:after, .custom-radio .custom-control-label:before {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: none !important;
    display: -webkit-box;
    display: flex;
    height: 1.42857rem;
    -webkit-box-pack: center;
    justify-content: center;
    left: -2.14286rem;
    margin: 0;
    outline: none !important;
    padding: 0;
    top: 0;
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, transform .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    width: 1.42857rem
}

.custom-radio .custom-control-label:before {
    background: #ebebeb;
    border: 1px solid #ebebeb;
    content: "";
    pointer-events: auto
}

.custom-radio .custom-control-label:after {
    background: #336cfb;
    border-radius: 50%;
    height: calc(1.42857rem - 8px);
    margin: 4px 0 0 4px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: background .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, transform .2s ease-in-out;
    transition: background .2s ease-in-out, transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    width: calc(1.42857rem - 8px)
}

.custom-radio .custom-control-label:hover {
    color: inherit
}

.custom-radio .custom-control-label:hover:before {
    background: #ebebeb;
    border-color: #ebebeb
}

.custom-radio .custom-control-label:hover:after {
    background: #336cfb;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
    background: #336cfb;
    border-color: #336cfb
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
    background: #fff;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.custom-radio .custom-control-input:disabled ~ .custom-control-label {
    color: #bdbdbd;
    cursor: not-allowed
}

.custom-radio .custom-control-input:disabled ~ .custom-control-label:before {
    background: hsla(0, 0%, 80%, .1);
    border-color: hsla(0, 0%, 80%, .5)
}

.custom-radio .custom-control-input:disabled ~ .custom-control-label:after {
    background: #bdbdbd;
    -webkit-transform: scale(0);
    transform: scale(0)
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
    background: hsla(0, 0%, 80%, .1);
    border-color: hsla(0, 0%, 80%, .5)
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:after {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.br-wrapper .rating.rating-sm + .br-widget {
    font-size: 1rem
}

.br-wrapper .rating.rating-lg + .br-widget {
    font-size: 2rem
}

.br-wrapper .br-widget {
    display: -webkit-box;
    display: flex;
    font-size: 1.43rem;
    margin: 0 -.17857rem;
    white-space: nowrap
}

.br-wrapper .br-widget a {
    color: #c1c1c1;
    cursor: pointer;
    display: block;
    font-size: inherit;
    margin: 0 .17857rem;
    -webkit-transition: border .2s ease-in-out, color .2s ease-in-out;
    transition: border .2s ease-in-out, color .2s ease-in-out;
    font-family: IcoFont !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    line-height: 1;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased
}

.br-wrapper .br-widget a:after {
    content: "\f000";
    line-height: 1
}

.br-wrapper .br-widget a.number {
    border: 2px solid #c1c1c1;
    min-width: 2.14286rem;
    padding: calc(.35715rem - 2px);
    text-align: center
}

.br-wrapper .br-widget a.br-active {
    color: #ffeb3d
}

.br-wrapper .br-widget a.br-active.number {
    border-color: #ffeb3d
}

.br-wrapper .br-widget a.br-selected {
    color: #faa110
}

.br-wrapper .br-widget a.br-selected.number {
    border-color: #faa110
}

.br-wrapper .br-widget.rating-numbered {
    font-size: 1rem
}

.br-wrapper .br-widget.br-readonly a {
    cursor: default
}

.bootstrap-select, .bootstrap-select .dropdown-toggle, .bootstrap-select .dropdown-toggle:focus {
    outline: none !important
}

.bootstrap-select, .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%
}

.bootstrap-select.show .form-control {
    background: rgba(51, 108, 251, .01);
    border-color: #336cfb;
    box-shadow: 0 8px 24px rgba(0, 0, 0, .12);
    color: #1f2022
}

.bootstrap-select.rounded-0 > .dropdown-toggle, .bootstrap-select.rounded-20 > .dropdown-toggle, .bootstrap-select.rounded-500 > .dropdown-toggle, .bootstrap-select.rounded-pill > .dropdown-toggle, .bootstrap-select.rounded > .dropdown-toggle {
    border-radius: inherit
}

.bootstrap-select .dropdown-menu li a span.check-mark {
    font-size: 1.5em;
    top: 2px
}

.custom-switch {
    padding-left: 3.57143rem
}

.custom-switch .custom-control-label {
    color: inherit;
    cursor: pointer;
    font-weight: 500
}

.custom-switch .custom-control-label:before {
    background: #ebebeb;
    border: 1px solid #ebebeb;
    border-radius: 500px;
    cursor: inherit;
    display: block;
    height: 1.42857rem;
    left: -3.57143rem;
    margin: 0;
    top: 0;
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    width: 2.85714rem
}

.custom-switch .custom-control-label:after {
    background: #1f2022;
    border-radius: 50%;
    display: block;
    height: .89286rem;
    left: -3.57143rem;
    margin-top: .26786rem;
    top: 0;
    -webkit-transform: translateX(.26786rem);
    transform: translateX(.26786rem);
    -webkit-transition: background .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition: background .2s ease-in-out, transform .2s ease-in-out;
    transition: background .2s ease-in-out, transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    width: .89286rem
}

.custom-switch .custom-control-label:hover:after {
    background: #336cfb
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:before {
    background: #336cfb;
    border-color: #336cfb
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
    background: #fff;
    -webkit-transform: translateX(1.69643rem);
    transform: translateX(1.69643rem)
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label, .custom-switch .custom-control-input:disabled ~ .custom-control-label {
    color: #bdbdbd;
    cursor: not-allowed
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-switch .custom-control-input:disabled ~ .custom-control-label:before {
    background: hsla(0, 0%, 80%, .1);
    border-color: hsla(0, 0%, 80%, .5)
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:after, .custom-switch .custom-control-input:disabled ~ .custom-control-label:after {
    background: #bdbdbd
}

.pagination {
    list-style-type: none;
    display: -webkit-box;
    display: flex;
    margin: 0 -.17857rem;
    padding: 0
}

.pagination .page-item {
    margin: 0 .17857rem
}

.pagination .page-item .page-link {
    background: #d4d3d3;
    border-color: #d4d3d3;
    border-radius: 500px;
    color: #fff;
    cursor: pointer;
    display: block;
    min-height: 2.14286rem;
    min-width: 2.14286rem;
    padding: .35714rem;
    text-align: center;
    text-decoration: none;
    -webkit-transition: background-color .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out;
    transition: background-color .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out
}

.pagination .page-item.active .page-link, .pagination .page-item .page-link.active, .pagination .page-item .page-link:hover {
    background: #336cfb;
    border-color: #336cfb;
    color: #fff
}

.pagination .page-item.disabled .page-link {
    background: rgba(212, 211, 211, .6);
    border-color: rgba(212, 211, 211, .6);
    color: #fff;
    pointer-events: none
}

.table {
    border-color: #fff;
    border-radius: 0;
    color: inherit
}

.table tr {
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
    will-change: background, border, box-shadow, color;
    width: 100%
}

.table tr td, .table tr th {
    border: 0;
    border-bottom: 2px solid #fff;
    padding: .71429rem;
    vertical-align: middle
}

.table tr td:first-child, .table tr th:first-child {
    padding-left: 1.42857rem
}

.table tr td:last-child, .table tr th:last-child {
    padding-right: 1.42857rem
}

.table tr th {
    background: rgba(31, 32, 34, .1);
    position: relative
}

.table tr td {
    background: hsla(0, 0%, 92.2%, .3)
}

.table tbody tr:last-child td, .table tbody tr:last-child th {
    border-bottom: none
}

.table .thead-dark th {
    background: #171819;
    border-color: #171819;
    color: #fff
}

.table .thead-light th {
    background: rgba(31, 32, 34, .1);
    border-color: rgba(31, 32, 34, .1);
    color: #1f2022
}

.table.table-dark {
    background: #414143;
    border-color: #8f9091;
    color: #fff
}

.table.table-dark tr td, .table.table-dark tr th {
    background: inherit
}

.table.table-dark tr th {
    background: #171819;
    color: #fff
}

.table.table-hover tbody tr {
    background: none;
    color: inherit
}

.table.table-hover tbody tr:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, .12);
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: 1
}

.table.table-bordered tbody tr td, .table.table-bordered tbody tr th, .table.table-bordered thead tr td, .table.table-bordered thead tr th {
    border: 2px solid #ebebeb
}

.table.table-striped tbody tr:nth-of-type(odd) {
    background: none
}

.table.table-striped tbody tr:nth-of-type(2n) td, .table.table-striped tbody tr:nth-of-type(2n) th {
    background: rgba(31, 32, 34, .08)
}

.table.table-borderless tbody tr td, .table.table-borderless tbody tr th, .table.table-borderless thead tr td, .table.table-borderless thead tr th {
    border: 0
}

.table.table-sm tr td, .table.table-sm tr th {
    padding: .35714rem
}

.table.table-sm tr td:first-child, .table.table-sm tr th:first-child {
    padding-left: .71429rem
}

.table.table-sm tr td:last-child, .table.table-sm tr th:last-child {
    padding-right: .71429rem
}

.table.table-active, .table .table-active, .table.table-active > td, .table .table-active > td, .table.table-active > th, .table .table-active > th {
    background: #0a0b0c;
    color: #fff
}

.table.table-secondary, .table .table-secondary, .table.table-secondary > td, .table .table-secondary > td, .table.table-secondary > th, .table .table-secondary > th {
    background: hsla(0, 0%, 92.2%, .3);
    color: inherit
}

.table.table-primary, .table .table-primary, .table.table-primary > td, .table .table-primary > td, .table.table-primary > th, .table .table-primary > th {
    background: #336cfb;
    color: #fff
}

.table.table-success, .table .table-success, .table.table-success > td, .table .table-success > td, .table.table-success > th, .table .table-success > th {
    background: #b7ce63;
    color: #000
}

.table.table-info, .table .table-info, .table.table-info > td, .table .table-info > td, .table.table-info > th, .table .table-info > th {
    background: #64b5f6;
    color: #000
}

.table.table-warning, .table .table-warning, .table.table-warning > td, .table .table-warning > td, .table.table-warning > th, .table .table-warning > th {
    background: #e9e165;
    color: #000
}

.table.table-danger, .table .table-danger, .table.table-danger > td, .table .table-danger > td, .table.table-danger > th, .table .table-danger > th {
    background: #ed5564;
    color: #fff
}

.table.table-light, .table .table-light, .table.table-light > td, .table .table-light > td, .table.table-light > th, .table .table-light > th {
    background: #e4e4e4;
    color: #000
}

.table.table-dark, .table .table-dark, .table.table-dark > td, .table .table-dark > td, .table.table-dark > th, .table .table-dark > th {
    background: #414143;
    color: #fff
}

.table .actions {
    display: -webkit-box;
    display: flex;
    margin: -.17857rem
}

.table .actions .btn {
    margin: .17857rem
}

.table-responsive {
    margin: -10px;
    padding: 10px;
    width: auto
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc_disabled:after, table.dataTable thead .sorting_desc_disabled:before {
    bottom: .7em
}

@media (max-width: 767px) {
    .dataTables_wrapper > .row > .col-sm-12 {
        overflow: auto
    }

    .dataTables_wrapper > .row .dataTables_paginate .pagination {
        -webkit-box-pack: center;
        justify-content: center;
        margin-top: .71429rem
    }
}

.v-timeline {
    display: block;
    position: relative
}

.v-timeline .line {
    background: #e4e4e4;
    bottom: 1.42857rem;
    left: 1.42857rem;
    margin: 0 -1px;
    position: absolute;
    top: 1.42857rem;
    width: 2px
}

.v-timeline .timeline-box .box-label {
    display: -webkit-box;
    display: flex;
    padding: .71429rem 0
}

.v-timeline .timeline-box .box-label .badge {
    position: relative
}

.v-timeline .timeline-box .box-items {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column
}

.v-timeline .timeline-box .box-items .item {
    display: -webkit-box;
    display: flex;
    margin: 1.42857rem -.71429rem;
    position: relative
}

.v-timeline .timeline-box .box-items .item .icon-block {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 0 .71429rem
}

.v-timeline .timeline-box .box-items .item .icon-block .item-icon {
    -webkit-box-align: center;
    align-items: center;
    background: #336cfb;
    border-radius: 50%;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-size: 1.7rem;
    height: 2.85714rem;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 2.85714rem;
    width: 2.85714rem
}

.v-timeline .timeline-box .box-items .item .content-block {
    -webkit-box-flex: 2;
    flex-grow: 2;
    margin: 0 .71429rem
}

.v-timeline .timeline-box .box-items .item .content-block .item-header, .v-timeline .timeline-box .box-items .item .content-block .item-header .item-title {
    margin: 0
}

.v-timeline .timeline-box .box-items .item .content-block .item-header .item-date {
    color: #bcbcbd;
    margin: 0;
    white-space: nowrap
}

.v-timeline .timeline-box .box-items .item .content-block .item-desc {
    margin-top: .71429rem
}

.v-timeline.dots .line {
    left: .35714rem
}

.v-timeline.dots .timeline-box .box-items .item .icon-block .item-icon {
    height: .71429rem;
    min-height: .71429rem;
    width: .71429rem
}

.v-timeline.dots .timeline-box .box-items .item .icon-block .item-icon:before {
    display: none
}

.v-timeline.align-right .line {
    left: auto;
    right: 1.42857rem
}

.v-timeline.align-right .timeline-box .box-label {
    -webkit-box-pack: end;
    justify-content: flex-end
}

.v-timeline.align-right .timeline-box .box-items .item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
    text-align: right
}

.v-timeline.align-right .timeline-box .box-items .item .content-block .item-header {
    -webkit-box-pack: end;
    justify-content: flex-end
}

.v-timeline.align-right .timeline-box .box-items .item .content-block .item-desc {
    text-align: right
}

.v-timeline.align-right.dots .line {
    left: auto;
    right: .35714rem
}

.v-timeline.align-center .line {
    display: none
}

.v-timeline.align-center .timeline-box .box-label {
    -webkit-box-pack: center;
    justify-content: center
}

.v-timeline.align-center .timeline-box .box-items .item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: .71429rem -.35714rem
}

.v-timeline.align-center .timeline-box .box-items .item .icon-block {
    margin-bottom: .71429rem
}

.v-timeline.align-center .timeline-box .box-items .item .content-block {
    padding-bottom: 2.85714rem;
    position: relative;
    text-align: center
}

.v-timeline.align-center .timeline-box .box-items .item .content-block:after {
    background: #e4e4e4;
    bottom: 0;
    content: "";
    height: 2.14286rem;
    left: 50%;
    margin-left: -1px;
    position: absolute;
    width: 2px
}

.v-timeline.align-center .timeline-box .box-items .item .content-block .item-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: .71429rem
}

.v-timeline.align-center .timeline-box:last-child .box-items .item:last-child .content-block {
    padding-bottom: 0
}

.v-timeline.align-center .timeline-box:last-child .box-items .item:last-child .content-block:after {
    display: none
}

@media (min-width: 768px) {
    .v-timeline.align-between .line {
        left: 50%
    }

    .v-timeline.align-between .timeline-box .box-label {
        -webkit-box-pack: center;
        justify-content: center
    }

    .v-timeline.align-between .timeline-box .box-items .item {
        margin-left: 0;
        margin-right: 0;
        width: 50%
    }

    .v-timeline.align-between .timeline-box .box-items .item:nth-child(odd) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        flex-direction: row-reverse;
        text-align: right
    }

    .v-timeline.align-between .timeline-box .box-items .item:nth-child(odd) .icon-block {
        margin-right: -1.42857rem
    }

    .v-timeline.align-between .timeline-box .box-items .item:nth-child(odd) .content-block {
        margin-left: 0
    }

    .v-timeline.align-between .timeline-box .box-items .item:nth-child(2n) {
        align-self: flex-end
    }

    .v-timeline.align-between .timeline-box .box-items .item:nth-child(2n) .icon-block {
        margin-left: -1.42857rem
    }

    .v-timeline.align-between .timeline-box .box-items .item:nth-child(2n) .content-block {
        margin-right: 0
    }

    .v-timeline.align-between.dots .timeline-box .box-items .item:nth-child(odd) .icon-block {
        margin-right: -.35714rem
    }

    .v-timeline.align-between.dots .timeline-box .box-items .item:nth-child(2n) .icon-block {
        margin-left: -.35714rem
    }
}

.icon {
    border-radius: 6px;
    display: -webkit-inline-box;
    display: inline-flex;
    font-size: 1.43rem;
    padding: .35714rem;
    -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, color .2s ease-in-out;
    transition: background .2s ease-in-out, border .2s ease-in-out, color .2s ease-in-out;
    vertical-align: top
}

.page-sign-in {
    background: url(../content/login-page.jpg) 50% 50% no-repeat
}

.page-sign-up {
    background: url(../content/register-page.jpg) 50% 50% no-repeat
}

.page-404 {
    background: url(../content/404-page.jpg) 50% 50% no-repeat
}

.page-404 .content-box {
    background: hsla(0, 0%, 100%, .9) !important
}

.page-404 .content-box .content-body .icon {
    color: rgba(237, 85, 100, .5);
    font-size: inherit;
    padding: 0
}

.page-500 {
    background: url(../content/500-page.jpg) 50% 50% no-repeat
}

.page-500 .content-box {
    background: hsla(0, 0%, 100%, .9) !important
}

.page-500 .content-box .content-body .icon {
    color: rgba(237, 85, 100, .5);
    font-size: inherit;
    padding: 0
}

.accent-palette {
    color: #336cfb
}

.text-danger {
    color: #ed5564 !important
}

.github-color {
    color: #24292e
}

.twitter-color {
    color: #1da1f2
}

.linkedin-color {
    color: #0073b1
}

.youtube-color {
    color: red
}

.nowrap {
    white-space: nowrap
}

.hidden {
    display: none !important
}

.no-style {
    background: none;
    border: 0;
    outline: none;
    padding: 0
}

.elements-list {
    -webkit-box-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: -.35714rem
}

.elements-list > * {
    margin: .35714rem
}

.chat-container {
    display: -webkit-box;
    display: flex;
    height: 200px !important;
    position: relative;
    overflow: hidden
}

.chat-container.container-h-100 {
    height: 100px !important
}

.chat-container.container-h-50 {
    height: 50px !important
}

.chat-container.container-h-300 {
    height: 300px !important
}

@media (max-width: 767px) {
    .chat-container.container-h-300 {
        height: 200px !important
    }
}

.chat-container.container-h-400 {
    height: 400px !important
}

@media (max-width: 767px) {
    .chat-container.container-h-400 {
        height: 300px !important
    }
}

.chat-container.negative-indents {
    margin-top: -1.42857rem;
    margin-bottom: -1.42857rem
}

.rotate {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.animated.delay-01s {
    -webkit-animation-delay: .1s;
    animation-delay: .1s
}

.animated.delay-02s {
    -webkit-animation-delay: .2s;
    animation-delay: .2s
}

.animated.delay-03s {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.animated.delay-04s {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.animated.delay-05s {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.animated.delay-06s {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.animated.delay-07s {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}

.animated.delay-08s {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.animated.delay-09s {
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}

.rounded-20 {
    border-radius: 20px !important
}

.rounded-500 {
    border-radius: 500px !important
}

.close {
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    opacity: 1;
    text-shadow: none;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out
}

.close:hover {
    color: #ed5564
}

.map {
    height: 100%
}

.icons-list {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: -.5rem
}

.icons-list .icon {
    font-size: 30px;
    margin: .5rem
}

.add-action-box {
    bottom: 5.71429rem;
    position: fixed;
    right: 1.42857rem;
    z-index: 9997
}

.add-action-box .btn {
    font-size: 30px;
    min-width: 5rem !important
}

.department .team {
    flex-wrap: wrap
}

.department .team .team-img {
    box-shadow: 0 18px 24px rgba(0, 0, 0, .12);
    margin-right: -10px
}

.bg-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(#ebebeb)) !important;
    background: linear-gradient(90deg, #fff, #ebebeb) !important
}

.address-col {
    min-width: 150px
}

.fs-20 {
    font-size: 20px
}

.fs-30 {
    font-size: 30px
}

.fs-48 {
    font-size: 48px
}

.personal-info-card .card-body {
    padding-top: 0 !important
}

.personal-info-card .user-actions {
    margin-top: -50px
}

.opacity-50 {
    opacity: .5
}

.spinner {
    align-items: center;
    color: #336cfb;
    display: flex;
    font-size: 30px;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 99999;
}

.cursor-pointer {
    cursor: pointer
}

.text-decoration-line-through {
    text-decoration: line-through
}

.text-white::placeholder {
    color: #ddd;
}

.invalid-field {
    border-color: red;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

.before-danger:before {
    background-color: #ed5564 !important;
}

.valid-input-field {
    border-color: #b7ce63;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23b7ce63' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 98% calc(0.375em + 0.3875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-input-field {
    border-color: #ed5564;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ed5564' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23ed5564' d='m0 0 3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 98% calc(0.375em + 0.3875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
