.cropper-container {
    /*text-align: center;
    !*position: absolute;*!
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.75);
    z-index: 200;
    padding: 60px;
    margin: 0;
    overflow: auto;*/
}

.crop-preview {
    object-fit: contain;
    background-color: #9b9a9a;
    width: 500px;
    height: auto;
    max-width: 100%;
    margin: 10px auto;
    display: block;
}

.crop-preview.profile {
    /*border-radius: 50%;*/
    border-radius: 10px;
    width: 250px;
    height: 250px;
}

.cropper-container p {
    font-size: 16px;
}

.btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    position: sticky;
    bottom: 0;
    padding: 20px;
    background: rgba(255,255,255,.75);
}

label {
    text-align: left;
}