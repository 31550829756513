.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.iti__flag {background-image: url("images/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("images/flags@2x.png");}
}

/* WARNING */
/* WARNING */
/* WARNING */
/* WARNING */
/* WARNING */
iframe {
  display: none;
}
/* WARNING */
/* WARNING */
/* WARNING */
/* WARNING */
/* WARNING */
/* WARNING */

/* RCTA */
.rcta-main-control {
  height: 90%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row: auto auto;
  grid-column-gap: 0;
  grid-row-gap: 0;
  /*max-height: 400px;*/
  max-height: 60%;
  margin-top: 40px;
}

.rcta-main-control__feature-item {
  display:flex;
  align-items:center;
  justify-content:center;
}
/*
  This put the background color of Chrome autofill as transparent
  https://stackoverflow.com/questions/29580704/change-input-background-to-transparent-on-autocomplete-form
*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  /*-webkit-text-fill-color: #fff !important;*/
}

.rcta-main-overlay {
  /*background: rgba(0,0,0,.75);*/
  background: rgba(255,255,255,.75);
  z-index: 300;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
}

.rounded {
  border-width: 1px;
  border-color: rgb(224, 224, 224);
  border-radius: 8px !important;
}

input.new-form-control {
  min-height: 48px;
  margin-top: 4px;
}

.new-form-control {
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  color: #1f2022;
  display: block;
  font-size: 1rem;
  height: auto;
  line-height: 1.43;
  outline: none;
  padding: calc(.71429rem - 1px) 1.42857rem;
  -webkit-transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
  transition: background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out, color .2s ease-in-out;
  will-change: background, border, box-shadow, color;
  width: 100%
}

.new-form-control ::-webkit-input-placeholder {
  color: #a8a8a8
}

.new-form-control :-moz-placeholder, .new-form-control ::-moz-placeholder {
  color: #a8a8a8
}

.new-form-control :-ms-input-placeholder {
  color: #a8a8a8
}

.input-group.date > div {
  width: 100%;
}

.rcta-progress-circle svg path {
  fill: transparent;
}

.rcta-hide-intercom [class^="intercom-"] {
  display: none;
}
